<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		id="modal-offer"
		v-model="isOpen"
		hide-footer
		:size="$screen.width <= 992 ? 'md' : 'lg'"
		@hidden="emitCloseEvent"
	>
		<template #modal-title>
			<span>
				{{ FormMSG(65, 'Edit post') }}
			</span>
		</template>
		<b-form @submit.prevent="handleSubmit">
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(72, 'Name')" label-cols="3" :horizontal="true">
						<b-input v-model="dataToEdit.name" type="text" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(73, 'Task type')" label-cols="3" :horizontal="true">
						<task-type-selector
							@task-type:selected="selectType"
							@task-type:clearSelection="clearType"
							:add-label="FormMSG(38, 'Add')"
							:placeholder="FormMSG(34, 'Choose type ...')"
							:task-type-id="task.taskTypeId ? task.taskTypeId : 0"
						/>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(74, 'Description')" label-cols="3" :horizontal="true">
						<b-form-textarea v-model="dataToEdit.description" rows="3" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<div>
						<b-table
							ref="materialOfferTable"
							:hover="hover"
							:items="taskTypeItems"
							:fields="materialFields"
							:current-page="currentPage"
							:per-page="perPage"
							:head-variant="hv"
							responsive="sm"
							bordered
							small
							:empty-text="FormMSG(2, 'No material found')"
							show-empty
						>
							<template v-slot:cell(unit)="data">
								<div v-if="data.item.unit > 0">
									{{ units[data.item.unit].text }}
								</div>
								<div v-else>
									{{ data.item.customUnit }}
								</div>
							</template>
						</b-table>
					</div>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<div class="float-right">
						<b-button :disabled="formBusy" @click="hideModal" class="mr-2">
							{{ FormMSG(10, 'Close') }}
						</b-button>
						<b-button variant="success" type="submit" :disabled="formBusy">
							<span v-if="formBusy">{{ FormMSG(43, 'Modification in progress') }}</span>
							<span v-else>{{ FormMSG(42, 'Save') }}</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-form>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import TaskTypeSelector from '@/components/TaskType/TaskTypeSelector';
import { getTaskTypeItems } from '@/cruds/tasktypes.crud';
import { rendCurrency } from '~helpers';
import { updatedTask } from '@/cruds/task.crud';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'PostModalOffer',

	components: {
		TaskTypeSelector
	},

	mixins: [languageMessages, GlobalMixin],

	props: {
		open: { type: Boolean, default: false, required: true },
		taskSelected: { type: Object, default: () => {} },
		clientId: { type: Number, default: 0, required: true },
		hasIndex: { type: Boolean, default: false, required: true }
	},

	data() {
		return {
			hover: true,
			hv: 'dark',
			perPage: 0,
			currentPage: 1,
			formBusy: false,
			task: {
				name: '',
				taskTypeId: 0,
				duration: '',
				dayDuration: 1,
				priority: 0
			},
			taskTypeItems: [],
			dataToEdit: {
				name: '',
				description: ''
			}
		};
	},

	computed: {
		units() {
			return this.FormMenu(1317);
		},
		materialFields() {
			return [
				{
					key: 'name',
					label: this.FormMSG(3, 'Name'),
					sortable: true
				},
				{
					key: 'unit',
					label: this.FormMSG(4, 'Unit'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'unitPrice',
					label: this.FormMSG(5, 'Price'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'quantity',
					label: this.FormMSG(6, 'Quantity'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'totalPrice',
					label: this.FormMSG(7, 'Total'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-center'
				}
			];
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	watch: {
		taskSelected: {
			async handler(val) {
				this.task.name = val.name;
				this.task.taskTypeId = val.taskTypeId;
				this.task.duration = val.duration;
				this.task.dayDuration = val.dayDuration;
				this.task.priority = val.priority;
				this.dataToEdit.name = val.name;
				this.dataToEdit.description = val.description;

				this.taskTypeItems = await getTaskTypeItems(val.taskTypeId);
			},
			deep: true,
			immediate: true
		},
		async 'task.taskTypeId'(val) {
			if (val !== undefined && val > 0) {
				this.taskTypeItems = await getTaskTypeItems(val);
			}
		}
	},

	methods: {
		async selectType(payload, arg) {
			if (payload !== null) {
				const { id, duration, dayDuration, description, priority, name } = payload;
				this.task.name = name;
				this.task.taskTypeId = parseInt(id, 10);
				this.task.duration = duration;
				this.task.dayDuration = 1;
				this.task.priority = priority;
				this.dataToEdit.name = name;
				this.dataToEdit.description = description;
			} else {
				this.task.taskTypeId = 0;
				this.task.duration = '0001-01-01T00:00:00Z';
				this.task.dayDuration = 1;
				this.task.priority = 0;
				this.task.name = '';
				this.dataToEdit.name = '';
				this.dataToEdit.description = '';
			}
		},
		clearType(payload) {
			if (payload) {
				this.task.taskTypeId = 0;
				this.task.duration = '0001-01-01T00:00:00Z';
				this.task.dayDuration = 1;
				this.task.priority = 0;
				this.task.name = '';
				this.task.description = '';
			}
		},
		hideModal() {
			this.emitCloseEvent();
			this.$bvModal.hide('modal-offer');
		},
		emitCloseEvent() {
			this.task = {};
			this.taskTypeItems = [];
			this.$emit('post-modal-offer:close');
		},
		async handleSubmit() {
			this.confirmModal(this.sendData, this.FormMSG(66, 'Are you sure ?'));
		},
		async sendData() {
			try {
				const updTask = {
					name: this.dataToEdit.name,
					description: this.dataToEdit.description,
					status: 9,
					priority: this.task.priority,
					clientId: this.clientId ? this.clientId : 0,
					taskTypeId: this.task.taskTypeId,
					taskPlanningId: parseInt(this.taskSelected.taskPlanningId)
				};
				await updatedTask(this.taskSelected.id, updTask);

				this.$emit('post-modal-offer:update', {
					node: {
						...this.taskSelected,
						...updTask,
						items: this.taskTypeItems
					},
					hasIndex: this.hasIndex
				});

				this.emitCloseEvent();
			} catch (e) {
				console.error(e.message);
			}
		}
	}
};
</script>

<style scoped></style>
