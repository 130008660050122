var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        {
          staticStyle: { border: "1px solid #dfdfdf !important", margin: "0" },
          on: {
            click: function ($event) {
              return _vm.$emit("tree-node:row-click", _vm.node.name)
            },
          },
        },
        [
          _c("b-col", { attrs: { sm: "3" } }, [
            _c(
              "div",
              {
                staticStyle: { "border-right": "0 !important" },
                style: { marginLeft: _vm.nodeMargin + "px" },
              },
              [
                _vm.hasChildren && !_vm.showChildren
                  ? _c("SvgPlus", { on: { clicked: _vm.toggleChildren } })
                  : _vm._e(),
                _vm.hasChildren && _vm.showChildren
                  ? _c("SvgMinus", { on: { clicked: _vm.toggleChildren } })
                  : _vm._e(),
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.lineNumber) +
                    " " +
                    _vm._s(_vm.node.name) +
                    "\n\t\t\t"
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { sm: "3" } }, [
            _vm.node.description.length > 0
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.right",
                        value: `${_vm.node.description}`,
                        expression: "`${node.description}`",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticStyle: { "border-right": "0 !important" },
                    attrs: { title: _vm.FormMSG(21, "Description") },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.truncate(_vm.node.description, 50)) +
                        "\n\t\t\t"
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c("b-col", { attrs: { sm: "1" } }),
          _c("b-col", { attrs: { sm: "1" } }),
          _c("b-col", { attrs: { sm: "1" } }),
          _c("b-col", { attrs: { sm: "1" } }),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { sm: "2" } },
            [
              _c(
                "b-dropdown",
                {
                  staticStyle: { "border-right": "0 !important" },
                  attrs: {
                    variant: "link",
                    "menu-class": "w-100",
                    "no-caret": "",
                    right: "",
                    disabled: _vm.parentNode.sendStatus === 5,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c(_vm.getLucideIcon(_vm.ICONS.PLUS_CIRCLE.name), {
                            tag: "component",
                            attrs: { size: 22 },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleClickNewPost(_vm.node)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c("i", {
                          staticClass: "fa fa-plus-circle",
                          staticStyle: {
                            "font-size": "1.15rem",
                            color: "green",
                          },
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]),
                      _vm._v(
                        _vm._s(_vm.FormMSG(48, "Sub element")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleClickNewMaterial(_vm.node)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c("i", {
                          staticClass: "fa fa-cart-plus",
                          staticStyle: {
                            "font-size": "1.15rem",
                            color: "green",
                          },
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]),
                      _vm._v(
                        _vm._s(_vm.FormMSG(49, "Material")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c("b-dropdown-form", [
                    _c(
                      "div",
                      {
                        staticClass: "p-0 cb-block-context-menu lh-21 pb-2",
                        staticStyle: { "border-right": "0 !important" },
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "px-0 ml-3",
                            staticStyle: { "border-right": "0 !important" },
                            on: {
                              change: function ($event) {
                                return _vm.saveLayoutPageBreakDoc(
                                  $event,
                                  _vm.node
                                )
                              },
                            },
                            model: {
                              value: _vm.node.prtPageBreak,
                              callback: function ($$v) {
                                _vm.$set(_vm.node, "prtPageBreak", $$v)
                              },
                              expression: "node.prtPageBreak",
                            },
                          },
                          [
                            _c("span", { staticClass: "pl-2" }, [
                              _vm._v(
                                _vm._s(_vm.FormMSG(9, "Break page (word) ?"))
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "p-0 cb-block-context-menu lh-21 pb-2",
                        staticStyle: { "border-right": "0 !important" },
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "px-0 ml-3",
                            staticStyle: { "border-right": "0 !important" },
                            on: {
                              change: function ($event) {
                                return _vm.saveLayoutLineDescriptionDoc(
                                  $event,
                                  _vm.node
                                )
                              },
                            },
                            model: {
                              value: _vm.node.prtLnDescriptionSection,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.node,
                                  "prtLnDescriptionSection",
                                  $$v
                                )
                              },
                              expression: "node.prtLnDescriptionSection",
                            },
                          },
                          [
                            _c("span", { staticClass: "pl-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(
                                    10,
                                    "Separate description line (word) ?"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "p-0 cb-block-context-menu lh-21 pb-1",
                        staticStyle: { "border-right": "0 !important" },
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "px-0 ml-3",
                            staticStyle: { "border-right": "0 !important" },
                            on: {
                              change: function ($event) {
                                return _vm.saveLayoutHideDetailDoc(
                                  $event,
                                  _vm.node
                                )
                              },
                            },
                            model: {
                              value: _vm.node.prtHideDetails,
                              callback: function ($$v) {
                                _vm.$set(_vm.node, "prtHideDetails", $$v)
                              },
                              expression: "node.prtHideDetails",
                            },
                          },
                          [
                            _c("span", { staticClass: "pl-2" }, [
                              _vm._v(
                                _vm._s(_vm.FormMSG(11, "Hide details (word) ?"))
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "link",
                    disabled:
                      _vm.lineNumber === "" || _vm.parentNode.sendStatus === 5,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickTreeNodeEditPost(_vm.node)
                    },
                  },
                },
                [
                  _c(_vm.getLucideIcon(_vm.ICONS.PENCIL.name), {
                    tag: "component",
                    attrs: { color: _vm.ICONS.PENCIL.color, size: 22 },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "link",
                    disabled:
                      _vm.lineNumber === "" || _vm.parentNode.sendStatus === 5,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickTreeNodeDeletePost(_vm.node)
                    },
                  },
                },
                [
                  _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                    tag: "component",
                    attrs: { color: _vm.ICONS.TRASH.color, size: 22 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasMaterial
        ? _c(
            "div",
            _vm._l(_vm.node.materials, function (material, index) {
              return _c(
                "b-row",
                {
                  key: material.taskPlanningId,
                  staticStyle: {
                    border: "1px solid #dfdfdf !important",
                    margin: "0",
                  },
                },
                [
                  _c("b-col", { attrs: { sm: "3" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          color: "#0d6efd",
                        },
                        style: { marginLeft: _vm.nodeMargin + 40 + "px" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.lineNumber) +
                            _vm._s(_vm.initialMaterial) +
                            "." +
                            _vm._s(index + 1) +
                            ". " +
                            _vm._s(material.name) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                  _c("b-col", { attrs: { sm: "3" } }, [_vm._v(" - ")]),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { sm: "1" } },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.getUnit(material)) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { sm: "1" } },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(material.test) +
                          "\n\t\t\t\t" +
                          _vm._s(material.quantity) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { sm: "1" } },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.currency(material.unitPrice)) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { sm: "1" } },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.currency(material.totalPrice)) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { sm: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "primary",
                            disabled: _vm.parentNode.sendStatus === 5,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleClickTreeNodeEditMaterial(
                                material
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "icon-pencil" })]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "danger",
                            disabled: _vm.parentNode.sendStatus === 5,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleClickTreeNodeDeleteMaterial(
                                material
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "icon-trash" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.hasChildren
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showChildren,
                  expression: "showChildren",
                },
              ],
            },
            _vm._l(_vm.node.children, function (child, index) {
              return _c("TreeNode", {
                key: child.taskPlanningId,
                attrs: {
                  "parent-node": _vm.parentNode,
                  node: child,
                  spacing: _vm.spacing + 10,
                  "line-number": _vm.lineNumber + "." + (index + 1),
                  "cur-index-parents": _vm.curIndexParents + "," + index,
                },
                on: {
                  "tree-node:new-post": _vm.handleTreeNodeNewPost,
                  "tree-node:edit-post": _vm.handleTreeNodeEditPost,
                  "tree-node:delete-post": _vm.handleTreeNodeDeletePost,
                  "tree-node:new-material": _vm.handleTreeNodeNewMaterial,
                  "tree-node:edit-material": _vm.handleTreeNodeEditMaterial,
                  "tree-node:delete-material": _vm.handleTreeNodeDeleteMaterial,
                },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }