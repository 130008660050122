<template>
	<error-container :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card no-body>
				<b-card-header>
					<b-row>
						<b-col sm="6">
							<h4>{{ FormMSG(1, 'Offer') }}</h4>
						</b-col>
						<b-col sm="6">
							<div class="float-right">
								<share-information
									:btn-label="FormMSG(59, 'Share offer')"
									:title-modal="FormMSG(60, 'Share the offer to :')"
									:offer="task"
									name-modal="modal-share-offer"
									icon="fa fa-share-alt"
									variant-btn="outline-secondary"
									class="mr-2"
								/>
								<b-dropdown
									split
									split-variant="outline-primary"
									variant="primary"
									:text="FormMSG(458, 'Download')"
									class="mr-2"
									menu-class="w-100"
									right
								>
									<b-dropdown-item @click="downloadOfferInPdf(task)">
										<span class="mr-2"><b-icon style="color: red" icon="file-earmark-pdf-fill" /></span>{{ FormMSG(6, 'PDF') }}
									</b-dropdown-item>
									<b-dropdown-item @click="downloadOfferInDoc(task)">
										<span class="mr-2"><b-icon style="color: #317ac1" icon="file-earmark-word-fill" /></span>{{ FormMSG(7, 'Word') }}
									</b-dropdown-item>
									<b-dropdown-item @click="downloadOfferInXls(task)">
										<span class="mr-2"><b-icon style="color: green" icon="file-excel-fill" /></span>{{ FormMSG(8, 'Excel') }}
									</b-dropdown-item>
								</b-dropdown>
								<b-button
									:style="`${!$screen.md ? 'width:100%' : ''}`"
									@click="backToTheList"
									variant="success"
									size="md"
									class="btn btn-primary btn-md float-right"
								>
									{{ this.FormMSG(2, 'Back to list') }}&nbsp;<i class="icon-action-undo"></i>
								</b-button>
							</div>
						</b-col>
					</b-row>
				</b-card-header>
				<b-card-body>
					<b-tabs cards active-nav-item-class="text-uppercase" active-tab-class="" title-item-class="text-uppercase" content-class="p-3">
						<b-tab :title="FormMSG(256, 'Generals')">
							<b-row class="mb-3">
								<b-col>
									<form-offer
										:task="task"
										:total-materials="totalBudget"
										@form-offer:update-info="handleFormOfferUpdateInfo"
										@form-offer:refreshTreeView="refreshTreeView"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<table-offer
										:task="task"
										:tree-view-tasks="treeViewTasks"
										@table-offer:new-post="handleTableOfferNewPost"
										@table-offer:delete-post="handleTableOfferDeletePost"
										@table-offer:update-material="handleTableOfferUpdateMaterial"
										@table-offer:delete-material="handleTableOfferDeleteMaterial"
									/>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab :title="FormMSG(68, 'Documents')">
							<b-row>
								<b-col sm="12">
									<b-row>
										<b-col sm="12">
											<b-card no-body header-tag="header" footer-tag="footer">
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-file-word-o" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(3, 'Offer Template') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<b-row>
														<b-col sm="12">
															<green-film-docs
																ref="documentTemplateList"
																:GFId="id"
																:show-input-file="false"
																GFType="task"
																GFSubType="offerDocumentTemplate"
																@el:updated="offerDocumentTemplate"
																@image-child:removed="onDocumentTemplateRemoved"
															/>
															<div
																v-if="elDocTemplateMounted === true && documentTemplateLength === 0"
																class="text-center empty-item-text"
															>
																{{ FormMSG(5, 'No offer template selected') }}
															</div>
														</b-col>
													</b-row>
												</b-card-body>
												<template #footer>
													<b-row>
														<b-col sm="9">&nbsp;</b-col>
														<b-col sm="3">
															<b-form-group class="my-0">
																<b-button
																	type="button"
																	variant="outline-primary"
																	size="md"
																	class="py-2 px-4 tags-assigned"
																	@click="openDocumentTemplateModal"
																	:disabled="documentTemplateLength > 0 || task.sendStatus === 5"
																	block
																>
																	<span>{{ FormMSG(4, 'Add') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-card no-body header-tag="header" footer-tag="footer">
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-files-o" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(25, 'Documents') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<b-row>
														<b-col sm="12">
															<green-film-docs
																ref="cardDocumentList"
																:GFId="id"
																:show-input-file="false"
																GFType="task"
																GFSubType="documents"
																@el:updated="greenFilmDocsMounted"
																:attachment-to-offer-order-invoice-only="true"
															/>
															<div v-if="elDocMounted === true && greenFilmDocLength === 0" class="text-center empty-item-text">
																{{ FormMSG(74, 'No document selected') }}
															</div>
														</b-col>
													</b-row>
												</b-card-body>
												<template #footer>
													<b-row>
														<b-col sm="9">&nbsp;</b-col>
														<b-col sm="3">
															<b-form-group class="my-0">
																<b-button
																	type="button"
																	variant="outline-primary"
																	size="md"
																	class="py-2 px-4 tags-assigned"
																	:disabled="task.sendStatus === 5"
																	@click="openDocumentModal"
																	block
																>
																	<span>{{ FormMSG(14, 'Add') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12">
											<b-card no-body header-tag="header" footer-tag="footer">
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-image" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(24, 'Images') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<div v-if="task.images.length === 0" ref="empty-image" class="text-center empty-item-text">
														{{ FormMSG(52, 'No image selected') }}
													</div>
													<ImgShowroomTable v-else :img-data="images" @image:removed="imageRemoved" />
												</b-card-body>
												<template #footer>
													<b-row>
														<b-col sm="3" offset="9">
															<b-form-group class="my-0">
																<b-button
																	type="button"
																	variant="outline-primary"
																	size="md"
																	class="py-2 px-4 tags-assigned"
																	:disabled="task.sendStatus === 5"
																	@click="togglePictureModal"
																	block
																>
																	<span>{{ FormMSG(13, 'Add') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
					<task-document-modal v-model="isOpenDocumentModal" :task-id="id" :is-offer="true" @task-document:hidden="onHideDocumentModal" />
					<offer-document-template-modal
						v-model="isOpenDocumentTemplateModal"
						:task-id="id"
						@offer-document-template:hidden="onHideDocumentTemplateModal"
						@offer-document-template:added="onDocumentTemplateAdded"
						@offer-document-template:removed="onDocumentTemplateRemoved"
					/>
					<capture
						v-model="isCaptureModalOpen"
						multiple-img
						dynamic-crop-size
						:edit-data="task"
						:parent-id="id"
						parent-type="task"
						parent-sub-type="images"
						@change="handleImgsTaked"
					/>
				</b-card-body>
			</b-card>
		</transition>
	</error-container>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import FormOffer from '@/components/Offers/FormOffer';
import TableOffer from '@/components/Offers/TableOffer';
import { isNil } from '~utils';
import { getTasks, getTask, updatedTask, getTaskItems } from '@/cruds/task.crud';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';
import Capture from '@/components/ExpenseService/Capture';
import ImgShowroomTable from '@/components/Task/ImgShowroomTable';
import TaskDocumentModal from '@/components/Modals/TaskDocumentModal';
import OfferDocumentTemplateModal from '@/components/Modals/OfferDocumentTemplateModal';
import OfferMixin from '@/components/Offers/offers.mixin';
import ShareInformation from '@/components/InfoSheet/ShareInformation';
import { store } from '@/store';

const reduceMaterials = (materials) => {
	return materials.reduce((acc, material) => {
		return acc + material.totalPrice;
	}, 0);
};

const calculDeepMaterials = (collection, materials = 0) => {
	for (const c of collection) {
		const redM = c.materials.reduce((acc, material) => {
			return acc + material.totalPrice;
		}, 0);
		if (!c.children) {
			return materials + redM;
		}
		return calculDeepMaterials(c.children, materials + redM);
	}
};
const priceWithPriceFactor = (unityPrice, internalPriceFactor, quantity = 0) => {
	const priceWithFactor = parseFloat(unityPrice) * parseFloat(internalPriceFactor);
	if (quantity > 0) {
		return quantity * priceWithFactor;
	}
	return priceWithFactor;
};

export default {
	name: 'OfferDetails',

	components: {
		FormOffer,
		TableOffer,
		GreenFilmDocs,
		Capture,
		ImgShowroomTable,
		TaskDocumentModal,
		ShareInformation,
		OfferDocumentTemplateModal
	},

	mixins: [languageMessages, OfferMixin],

	data() {
		return {
			erreur: {},
			warning: '',
			id: 0,
			task: {
				images: []
			},
			treeViewTasks: [],
			taskRoot: null,
			totalBudget: 0,
			elDocMounted: false,
			greenFilmDocLength: 0,
			isCaptureModalOpen: false,
			isOpenDocumentModal: false,
			isOpenDocumentTemplateModal: false,
			forTestTotalInit: 0,
			elDocTemplateMounted: false,
			documentTemplateLength: 0
		};
	},

	computed: {
		images() {
			const tmp = [];
			this.task.images.forEach((img) => {
				tmp.push({
					filename: img
				});
			});

			return tmp;
		}
	},

	watch: {
		async forTestTotalInit(val) {
			if (parseFloat(this.task.totalBudget) !== parseFloat(val)) {
				/* alert("TOTAL BUDGET " + this.task.totalBudget)
        alert("TOTAL BUDGET FOR TEST " + val) */
				this.task.totalBudget = val;
				this.task.totalBudgetTtc = await this.calcTtc(this.task.totalBudget);
				await updatedTask(this.task.id, {
					totalBudget: this.task.totalBudget,
					totalBudgetTtc: this.task.totalBudgetTtc,
					taskPlanningId: this.task.taskPlanningId
				});
				this.totalBudget = val;
			}
		}
	},

	mounted() {
		this.$nextTick(async () => {
			await this.getTask();
			this.setTreeView();
			this.reloadDocumentList(this.task.id);
			this.reloadDocumentTemplate(this.task.id);
		});
	},

	methods: {
		backToTheList() {
			if (store.state.isAdministrationOffers) {
				this.$router.replace('/administration/offers');
			} else {
				this.$router.replace('/offers');
			}
		},
		calcTtc(amount) {
			let totalVat = 0;
			if (this.task.vat !== '' && parseFloat(this.task.vat) !== 0) {
				totalVat = (parseFloat(amount) * parseFloat(this.task.vat)) / 100;
			}

			return parseFloat(amount) + totalVat;
		},
		async reloadDocumentList(taskId) {
			this.$refs.cardDocumentList.reloadData(taskId);
		},
		async reloadDocumentTemplate(taskId) {
			this.$refs.documentTemplateList.reloadData(taskId);
		},
		imageRemoved(payload) {
			this.task.images.splice(
				this.task.images.findIndex((image) => (image.lastIndexOf('.') === -1 ? image : image.substring(0, image.lastIndexOf('.')) === payload)),
				1
			);
		},
		onHideDocumentModal(payload) {
			this.isOpenDocumentModal = false;
			// do reload table documents in card
			this.reloadDocumentList(payload);
		},
		openDocumentModal() {
			this.isOpenDocumentModal = true;
		},
		async handleImgsTaked(imgs) {
			for (let i = 0; i < imgs.length; i++) {
				const imgSplitted = imgs[i].split('.')[0];
				this.task.images.push(imgSplitted);
			}
		},
		togglePictureModal() {
			this.isCaptureModalOpen = true;
		},
		greenFilmDocsMounted(payload) {
			this.elDocMounted = true;
			this.greenFilmDocLength = payload;
		},
		handleFormOfferUpdateInfo(payload) {
			this.treeViewTasks[0].description = payload.description;
			this.treeViewTasks[0].name = payload.name;
			this.treeViewTasks[0].clientId = payload.clientId;
			this.treeViewTasks[0].locationId = payload.locationId;
			this.treeViewTasks[0].addressId = payload.addressId;
			this.task.internalPriceFactor = payload.internalPriceFactor;
		},
		handleTableOfferDeleteMaterial(payload) {
			let materialSelected = payload.material;

			const curIndexTree = payload.curIndexParents;
			const curIndexTreeSplited = curIndexTree.split(',');
			let actionToPushString = '';
			for (let i = 0; i < curIndexTreeSplited.length; i++) {
				if (i === 0) {
					actionToPushString = 'this.treeViewTasks[0]';
				} else {
					actionToPushString += `.children[${curIndexTreeSplited[i]}]`;
				}
			}
			let actionToPushVariable = eval(actionToPushString);
			const newListMaterials = actionToPushVariable.materials.filter((m) => m.id !== materialSelected.id);
			actionToPushVariable.materials = newListMaterials;
			this.totalBudget -= actionToPushVariable.oldSumMaterials;
			const sumMaterials = reduceMaterials(actionToPushVariable.materials);
			this.totalBudget += sumMaterials;
			actionToPushVariable.oldSumMaterials = sumMaterials;
		},
		handleTableOfferDeletePost(payload) {
			let nodeSelected = payload.node;

			const curIndexTree = payload.curIndexParents;
			const curIndexTreeSplited = curIndexTree.split(',');
			let actionToPushString = '';

			for (let i = 0; i < curIndexTreeSplited.length - 1; i++) {
				if (i === 0) {
					actionToPushString = 'this.treeViewTasks[0]';
				} else {
					actionToPushString += `.children[${curIndexTreeSplited[i]}]`;
				}
			}

			let actionToPushVariable = eval(actionToPushString);

			const findIndex = actionToPushVariable.children.findIndex((v) => parseInt(v.taskPlanningId) === parseInt(nodeSelected.taskPlanningId));
			const collection = [actionToPushVariable.children[findIndex]];
			this.totalBudget -= calculDeepMaterials(collection);
			const newChildrens = actionToPushVariable.children.filter(
				(v) =>
					(parseInt(v.id) !== parseInt(nodeSelected.id) || parseInt(v.id) === parseInt(nodeSelected.id)) &&
					parseInt(v.taskPlanningId) !== parseInt(nodeSelected.taskPlanningId)
			);
			actionToPushVariable.children = newChildrens;
		},
		handleTableOfferUpdateMaterial(payload) {
			let materialSelected = payload.material;

			const curIndexTree = payload.curIndexParents;
			const curIndexTreeSplited = curIndexTree.split(',');
			let actionToPushString = '';
			for (let i = 0; i < curIndexTreeSplited.length; i++) {
				if (i === 0) {
					actionToPushString = 'this.treeViewTasks[0]';
				} else {
					actionToPushString += `.children[${curIndexTreeSplited[i]}]`;
				}
			}

			let actionToPushVariable = eval(actionToPushString);
			if (!payload.isUpdate) {
				if (!actionToPushVariable.materials) {
					this.$set(actionToPushVariable, 'materials', []);
				}

				this.totalBudget -= actionToPushVariable.oldSumMaterials;
				actionToPushVariable.materials.push(materialSelected);
				const sumMaterials = reduceMaterials(actionToPushVariable.materials);
				this.totalBudget += sumMaterials;
				actionToPushVariable.oldSumMaterials = sumMaterials;
			} else {
				const findIndex = actionToPushVariable.materials.findIndex((m) => m.id === materialSelected.id);

				if (findIndex !== -1) {
					this.totalBudget -= actionToPushVariable.oldSumMaterials;
					actionToPushVariable.materials[findIndex].name = materialSelected.name;
					actionToPushVariable.materials[findIndex].unit = materialSelected.unit;
					actionToPushVariable.materials[findIndex].customUnit = materialSelected.customUnit;
					actionToPushVariable.materials[findIndex].quantity = materialSelected.quantity;
					actionToPushVariable.materials[findIndex].unitPrice = priceWithPriceFactor(materialSelected.unitPrice, this.task.internalPriceFactor);
					// multiply price factor to each new material
					actionToPushVariable.materials[findIndex].totalPrice = priceWithPriceFactor(
						materialSelected.unitPrice,
						this.task.internalPriceFactor,
						materialSelected.quantity
					);

					const sumMaterials = reduceMaterials(actionToPushVariable.materials);
					this.totalBudget += sumMaterials;
					actionToPushVariable.oldSumMaterials = sumMaterials;
				}
			}
		},
		async handleTableOfferNewPost(payload) {
			let nodeSelected = payload.updTask;
			nodeSelected.materials = [];

			const curIndexTree = payload.curIndexParents;
			const curIndexTreeSplited = curIndexTree.split(',');
			let actionToPushString = '';
			for (let i = 0; i < curIndexTreeSplited.length; i++) {
				if (i === 0) {
					actionToPushString = 'this.treeViewTasks[0]';
				} else {
					actionToPushString += `.children[${curIndexTreeSplited[i]}]`;
				}
			}
			let actionToPushVariable = eval(actionToPushString);
			if (!payload.isUpdate) {
				if (!actionToPushVariable.children) {
					this.$set(actionToPushVariable, 'children', []);
				}
				nodeSelected.oldSumMaterials = 0;

				actionToPushVariable.children.push(nodeSelected);
			} else {
				if (!payload.hasIndex) {
					const findIndex = actionToPushVariable.children.findIndex((v) => v.id === nodeSelected.id);

					if (findIndex !== -1) {
						if (parseFloat(this.task.internalPriceFactor) !== 1) {
							nodeSelected.items = await getTaskItems(nodeSelected.id);
							nodeSelected.items = nodeSelected.items.map((material) => ({
								...material,
								unitPrice: priceWithPriceFactor(material.unitPrice, this.task.internalPriceFactor),
								totalPrice: priceWithPriceFactor(material.unitPrice, this.task.internalPriceFactor, material.quantity)
							}));
						}
						const sumMaterials = reduceMaterials(nodeSelected.items);
						this.totalBudget -= actionToPushVariable.children[findIndex].oldSumMaterials;
						actionToPushVariable.children[findIndex].taskPlanningId = nodeSelected.taskPlanningId;
						actionToPushVariable.children[findIndex].name = nodeSelected.name;
						actionToPushVariable.children[findIndex].description = nodeSelected.description;
						actionToPushVariable.children[findIndex].taskTypeId = nodeSelected.taskTypeId;
						actionToPushVariable.children[findIndex].marginLeft = 0;
						actionToPushVariable.children[findIndex].materials = nodeSelected.items;
						actionToPushVariable.children[findIndex].oldSumMaterials = sumMaterials;

						this.totalBudget += sumMaterials;
					}
				} else {
					if (parseFloat(this.task.internalPriceFactor) !== 1) {
						nodeSelected.items = await getTaskItems(nodeSelected.id);
						nodeSelected.items = nodeSelected.items.map((material) => ({
							...material,
							unitPrice: priceWithPriceFactor(material.unitPrice, this.task.internalPriceFactor),
							totalPrice: priceWithPriceFactor(material.unitPrice, this.task.internalPriceFactor, material.quantity)
						}));
					}
					const sumMaterials = reduceMaterials(nodeSelected.items);
					this.totalBudget -= actionToPushVariable.oldSumMaterials;
					actionToPushVariable.taskPlanningId = nodeSelected.taskPlanningId;
					actionToPushVariable.name = nodeSelected.name;
					actionToPushVariable.description = nodeSelected.description;
					actionToPushVariable.taskTypeId = nodeSelected.taskTypeId;
					actionToPushVariable.marginLeft = 0;
					actionToPushVariable.materials = nodeSelected.items;
					actionToPushVariable.oldSumMaterials = sumMaterials;
					this.totalBudget += sumMaterials;
				}
				this.setTreeView();
			}
		},
		async getTask() {
			this.task = await getTask(this.$route.params.id);
			// console.log({offer: this.task})
			this.totalBudget = this.task.totalBudget;
			this.id = parseInt(this.task.id);
			this.taskRoot = parseInt(this.task.rootId) === 0 ? parseInt(this.task.id) : parseInt(this.task.rootId);
		},

		setTreeView() {
			this.$loading(true);
			// set tree view
			new Promise(async (resolve, reject) => {
				resolve(await this.getTreeView(this.taskRoot, { tree: [], indexes: [] }, 0, this.task.internalPriceFactor));
				reject(false);
			}).then((response) => {
				if (response !== false) {
					setTimeout(() => {
						this.treeViewTasks = response.tree;
						this.forTestTotalInit = response.mat;
						this.$loading(false);
					}, 500);
				}
			});
		},
		async getTreeView(rootId, treeView, materials = 0, internalPriceFactor) {
			if (isNil(rootId)) return;
			let initIndex = 0;
			let { tree, indexes } = treeView;
			if (tree.length === 0) {
				if (rootId === this.task.id) {
					if (parseFloat(internalPriceFactor) !== 1) {
						const tMaterials = this.task.items;
						this.task.items = tMaterials.map((material) => {
							return {
								...material,
								//unitPrice: parseFloat(material.unitPrice) * parseFloat(this.task.internalPriceFactor),
								unitPrice: priceWithPriceFactor(material.unitPrice, internalPriceFactor),
								//totalPrice: parseFloat(material.totalPrice) * parseFloat(this.task.internalPriceFactor),
								totalPrice: priceWithPriceFactor(material.unitPrice, internalPriceFactor, material.quantity)
							};
						});
					}
					const sumMaterials = await reduceMaterials(this.task.items);
					tree.push({
						id: parseInt(this.task.id, 10),
						taskPlanningId: parseInt(this.task.taskPlanningId, 10),
						name: this.task.name,
						description: this.task.description,
						materials: this.task.items,
						clientId: this.task.clientId,
						locationId: this.task.locationId,
						addressId: this.task.addressId,
						oldSumMaterials: sumMaterials,
						taskTypeId: parseInt(this.task.taskTypeId, 10),
						marginLeft: 0,
						prtPageBreak: this.task.prtPageBreak,
						prtLnDescriptionSection: this.task.prtLnDescriptionSection,
						prtHideDetails: this.task.prtHideDetails
					});
					materials += sumMaterials;
				} else {
					const task = await getTask(null, rootId);
					if (parseFloat(internalPriceFactor) !== 1) {
						const tMaterials = task.items;
						task.items = tMaterials.map((material) => {
							return {
								...material,
								//unitPrice: parseFloat(material.unitPrice) * parseFloat(task.internalPriceFactor),
								unitPrice: priceWithPriceFactor(material.unitPrice, internalPriceFactor),
								//totalPrice: parseFloat(material.totalPrice) * parseFloat(task.internalPriceFactor),
								totalPrice: priceWithPriceFactor(material.unitPrice, internalPriceFactor, material.quantity)
							};
						});
					}
					const sumMaterials = await reduceMaterials(task.items);
					tree.push({
						id: parseInt(task.id, 10),
						taskPlanningId: parseInt(task.taskPlanningId, 10),
						name: task.name,
						description: task.description,
						materials: task.items,
						clientId: task.clientId,
						locationId: task.locationId,
						addressId: task.addressId,
						oldSumMaterials: sumMaterials,
						taskTypeId: parseInt(task.taskTypeId, 10),
						marginLeft: 0,
						prtPageBreak: this.task.prtPageBreak,
						prtLnDescriptionSection: this.task.prtLnDescriptionSection,
						prtHideDetails: this.task.prtHideDetails
					});
					materials += sumMaterials;
				}
				indexes.push(initIndex);
			}
			const getDT = await this.getDeepTree(rootId, { tree, indexes }, materials, internalPriceFactor);
			const tmpTree = getDT.rootTree;
			const mat = getDT.materials;
			return {
				tree: tmpTree,
				mat
			};
		},
		async getDeepTree(rootId, treeView, materials, internalPriceFactor) {
			let { tree, indexes } = treeView;
			let treeData = treeView;
			let data = await getTasks(rootId, {
				ofToday: false,
				orderBy: true,
				loadItems: true,
				loadSubTasks: true,
				loadTeams: false,
				loadUsers: false,
				loadTreeView: false,
				loadOffer: true
			});
			if (data.length > 0) {
				let getDeep;
				for (const [index, lastIndex] of indexes.entries()) {
					if (index === 0) {
						getDeep = tree[lastIndex];
					} else {
						getDeep = getDeep.children[lastIndex];
					}
				}
				this.$set(getDeep, 'children', []);
				const sIndexes = indexes.join(',');
				for (const [index, node] of data.entries()) {
					indexes.push(index);
					// let numbering = '';
					// indexes.forEach((value, index) => {
					//   if (index > 0) {
					//     numbering += (value + 1).toString() + '.';
					//   }
					// });
					const tMaterials = node.items;
					node.items = tMaterials.map((material) => {
						return {
							...material,
							//unitPrice: parseFloat(material.unitPrice) * parseFloat(node.internalPriceFactor),
							unitPrice: priceWithPriceFactor(material.unitPrice, internalPriceFactor),
							//totalPrice: parseFloat(material.totalPrice) * parseFloat(node.internalPriceFactor),
							totalPrice: priceWithPriceFactor(material.unitPrice, internalPriceFactor, material.quantity)
						};
					});
					const sumMaterials = await reduceMaterials(node.items);
					getDeep.children.push({
						id: parseInt(node.id, 10),
						taskPlanningId: parseInt(node.taskPlanningId, 10),
						name: node.name,
						description: node.description,
						materials: node.items,
						clientId: node.clientId,
						locationId: node.locationId,
						addressId: node.addressId,
						oldSumMaterials: sumMaterials,
						taskTypeId: parseInt(node.taskTypeId, 10),
						marginLeft: getDeep.marginLeft + 30,
						prtPageBreak: node.prtPageBreak,
						prtLnDescriptionSection: node.prtLnDescriptionSection,
						prtHideDetails: node.prtHideDetails
					});
					//merge
					_.merge(tree, getDeep);

					treeData = { tree, indexes };
					materials += sumMaterials;
					const getT = await this.getTreeView(node.id, treeData, materials, internalPriceFactor);
					materials = getT.mat;
					indexes = [];
					sIndexes.split(',').forEach((value) => {
						indexes.push(parseInt(value, 10));
					});
				}
			}

			return {
				rootTree: treeData.tree,
				materials
			};
		},
		openDocumentTemplateModal() {
			this.isOpenDocumentTemplateModal = true;
		},
		onHideDocumentTemplateModal(payload) {
			this.isOpenDocumentTemplateModal = false;
			// do reload table documents in card
			this.reloadDocumentTemplate(payload);
		},
		offerDocumentTemplate(payload) {
			this.elDocTemplateMounted = true;
			this.documentTemplateLength = payload;
		},
		async onDocumentTemplateAdded(payload) {
			// console.log('OfferDetails template added: ', payload);
			await updatedTask(this.task.id, {
				offerDocTemplateXid: payload.xid,
				taskPlanningId: this.task.taskPlanningId
			}).then((res) => {
				if (res) {
					this.task.offerDocTemplateXid = payload.xid;
				}
			});
		},
		async onDocumentTemplateRemoved(payload) {
			// console.log('OfferDetails template removed: ', payload);
			await updatedTask(this.task.id, {
				offerDocTemplateXid: '',
				taskPlanningId: this.task.taskPlanningId
			}).then((res) => {
				if (res) {
					this.task.offerDocTemplateXid = '';
				}
			});
		},
		refreshTreeView(payload) {
			if (payload) {
				this.setTreeView();
			}
		}
	}
};
</script>

<style scoped></style>
