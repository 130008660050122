var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      staticStyle: { "padding-top": "15px" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("fieldset", { staticClass: "scheduler-border" }, [
              _c(
                "div",
                { staticStyle: { "padding-top": "13px" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(1, "N°"),
                        "label-for": "offer-number",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-number",
                          type: "text",
                          placeholder: _vm.FormMSG(2, "Number..."),
                          disabled: "",
                        },
                        model: {
                          value: _vm.dataToEdit.offerNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "offerNumber", $$v)
                          },
                          expression: "dataToEdit.offerNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(3, "N° order"),
                        "label-for": "offer-order",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-order",
                          type: "text",
                          placeholder: _vm.FormMSG(11, "Order Number..."),
                          disabled: "",
                        },
                        model: {
                          value: _vm.dataToEdit.orderNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "orderNumber", $$v)
                          },
                          expression: "dataToEdit.orderNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(6, "Name"),
                        "label-for": "offer-name",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-name",
                          type: "text",
                          placeholder: _vm.FormMSG(7, "Offer name"),
                          disabled: _vm.task.sendStatus === 5,
                        },
                        model: {
                          value: _vm.$v.dataToEdit.name.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.dataToEdit.name, "$model", $$v)
                          },
                          expression: "$v.dataToEdit.name.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(8, "Offer description"),
                        "label-for": "offer-description",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "offer-description",
                          type: "text",
                          placeholder: _vm.FormMSG(9, "Offer description..."),
                          rows: "16",
                          disabled: _vm.task.sendStatus === 5,
                        },
                        model: {
                          value: _vm.$v.dataToEdit.description.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.dataToEdit.description,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.dataToEdit.description.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(18, "Customer"),
                        "label-for": "offer-customer",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("customer-selector", {
                        attrs: {
                          "client-id": _vm.dataToEdit.clientId,
                          "location-id": _vm.dataToEdit.locationId,
                          "add-label": _vm.FormMSG(37, "Add"),
                          "update-label": _vm.FormMSG(76, "Update"),
                          placeholder: _vm.FormMSG(32, "Choose customer ..."),
                          "location-placeholder": _vm.FormMSG(
                            75,
                            "Choose location ..."
                          ),
                          disabled: _vm.task.sendStatus === 5,
                          "can-do-add": _vm.task.sendStatus !== 5,
                        },
                        on: {
                          "client:selected": _vm.selectCustomer,
                          "client-location:selected": _vm.selectLocation,
                          "client-location:removed": _vm.onLocationRemoved,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        label: _vm.FormMSG(35, "Invoice address"),
                        "label-for": "offer-invoice-address",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row mt-0" },
                        [
                          _vm.task.sendStatus !== 5
                            ? _c(
                                "b-button",
                                {
                                  staticStyle: { height: "30px" },
                                  attrs: {
                                    pressed: _vm.invoiceAddressToggle,
                                    variant: `${
                                      _vm.isAddressClientUsed
                                        ? "success"
                                        : "danger"
                                    }`,
                                    size: "md",
                                  },
                                  on: {
                                    "update:pressed": function ($event) {
                                      _vm.invoiceAddressToggle = $event
                                    },
                                    click: _vm.handleInvoiceAddress,
                                  },
                                },
                                [
                                  _c("i", {
                                    class: `${
                                      _vm.isAddressClientUsed
                                        ? "fa fa-check"
                                        : "fa fa-trash"
                                    }`,
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          !_vm.isAddressClientUsed
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-flex flex-row mt-1",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-1 text-warning",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: { id: "tooltip-invoice-address" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-map-marker",
                                        staticStyle: { "font-size": "1.25rem" },
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                    ]
                                  ),
                                  _vm.task.sendStatus !== 5
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "col-7",
                                          staticStyle: { bottom: "5px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row supplier-action-update",
                                              class: { has_two_btn: true },
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  on: {
                                                    click:
                                                      _vm.openEditInvoiceAddressrModal,
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-edit",
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    block: "",
                                                    variant: "outline-primary",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.openEditInvoiceAddressrModal,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          41,
                                                          "Update"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "tooltip-invoice-address",
                                        triggers: "hover",
                                        placement: "top",
                                        variant: "info",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.editSupplierPayload.name
                                                  )
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2287446399
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.getAddressFormater(
                                              _vm.editSupplierPayload.address
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mt-1 alert alert-info",
                          staticStyle: { color: "rgba(0, 0, 0, 0.7)" },
                          attrs: { role: "alert" },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  36,
                                  `The client address will be used by default (i.e. when the box checked), but you can always use another address by unchecking the box.`
                                )
                              ) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "clearfix",
                      attrs: {
                        label: _vm.FormMSG(26, "Architect"),
                        "label-for": "offer-architect",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("supplier-selector", {
                        ref: "refSupplierArchitect",
                        attrs: {
                          title: `${
                            !_vm.isArchitectUpdate
                              ? _vm.FormMSG(53, "Add an architect")
                              : _vm.FormMSG(54, "Update architect details")
                          }`,
                          addlabel: _vm.FormMSG(28, "Add"),
                          "update-label": _vm.FormMSG(29, "Update"),
                          placeholder: _vm.FormMSG(30, "Choose architect ..."),
                          "supplier-id": _vm.architectId,
                          "show-default-label": false,
                          disabled: _vm.task.sendStatus === 5,
                          type: "architect",
                        },
                        on: {
                          change: _vm.handleSupplierArchitect,
                          "supplier:unselected": _vm.onRemoveSupplierArchitect,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(27, "Syndic"),
                        "label-for": "offer-syndic",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("supplier-selector", {
                        ref: "refSupplierSyndic",
                        attrs: {
                          title: `${
                            !_vm.isSyndictUpdate
                              ? _vm.FormMSG(55, "Add a syndic")
                              : _vm.FormMSG(56, "Update syndic details")
                          }`,
                          addlabel: _vm.FormMSG(31, "Add"),
                          "update-label": _vm.FormMSG(33, "Update"),
                          placeholder: _vm.FormMSG(34, "Choose syndic ..."),
                          "supplier-id": _vm.syndicId,
                          "show-default-label": false,
                          disabled: _vm.task.sendStatus === 5,
                          type: "syndic",
                        },
                        on: {
                          change: _vm.handleSupplierSyndic,
                          "supplier:unselected": _vm.onRemoveSupplierSyndic,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(10, "Offer date"),
                        "label-for": "offer-date",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-datepicker", {
                        attrs: {
                          id: "offer-date",
                          placeholder: _vm.FormMSG(5, "Offer date"),
                          disabled: _vm.task.sendStatus === 5,
                        },
                        model: {
                          value: _vm.dataToEdit.offerDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "offerDate", $$v)
                          },
                          expression: "dataToEdit.offerDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("fieldset", { staticClass: "scheduler-border" }, [
              _c(
                "div",
                { staticStyle: { "padding-top": "15px" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(25, "Deposit") + " (%)",
                        "label-for": "offer-vat",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-vat",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          placeholder: "0",
                          disabled: _vm.task.sendStatus === 5,
                        },
                        model: {
                          value: _vm.dataToEdit.percentDeposit,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "percentDeposit", $$v)
                          },
                          expression: "dataToEdit.percentDeposit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(51, "Price factor"),
                        "label-for": "offer-factor",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-factor",
                          type: "number",
                          step: "0.01",
                          min: "0.01",
                          placeholder: "0",
                          disabled: _vm.task.sendStatus === 5,
                        },
                        on: { blur: _vm.validatePricefactor },
                        model: {
                          value: _vm.dataToEdit.internalPriceFactor,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "internalPriceFactor", $$v)
                          },
                          expression: "dataToEdit.internalPriceFactor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(24, "Discount") + " (%)",
                        "label-for": "offer-vat",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-vat",
                          type: "number",
                          step: "0.01",
                          min: "0",
                          placeholder: "0",
                          disabled: _vm.task.sendStatus === 5,
                        },
                        on: {
                          update: _vm.setInit,
                          click: _vm.setInit,
                          focus: _vm.setInit,
                        },
                        model: {
                          value: _vm.dataToEdit.percentDiscount,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "percentDiscount", $$v)
                          },
                          expression: "dataToEdit.percentDiscount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(19, "VAT") + " (%)",
                        "label-for": "offer-vat",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-vat",
                          type: "number",
                          placeholder: "0",
                          step: "0.01",
                          min: "0",
                          disabled: _vm.task.sendStatus === 5,
                        },
                        on: {
                          update: _vm.setInit,
                          click: _vm.setInit,
                          focus: _vm.setInit,
                        },
                        model: {
                          value: _vm.dataToEdit.vat,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "vat", $$v)
                          },
                          expression: "dataToEdit.vat",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(21, "Total budget"),
                        "label-for": "offer-total-budget",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-total-budget",
                          type: "text",
                          placeholder: "0",
                          disabled: "",
                        },
                        model: {
                          value: _vm.totalBudgetFormatter,
                          callback: function ($$v) {
                            _vm.totalBudgetFormatter = $$v
                          },
                          expression: "totalBudgetFormatter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(22, "Total budget TTC"),
                        "label-for": "offer-total-budget-ttc",
                        "label-cols": 3,
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "offer-total-budget-ttc",
                          type: "text",
                          placeholder: "0",
                          disabled: "",
                        },
                        model: {
                          value: _vm.totalBudgetTtcFormatter,
                          callback: function ($$v) {
                            _vm.totalBudgetTtcFormatter = $$v
                          },
                          expression: "totalBudgetTtcFormatter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "2", offset: "10" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    block: "",
                    disabled:
                      !_vm.$v.dataToEdit.name.required ||
                      !_vm.$v.dataToEdit.description.required ||
                      _vm.formBusy,
                  },
                },
                [
                  _vm.formBusy
                    ? _c("b-spinner", { attrs: { label: "Loading..." } })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.FormMSG(4, "Save")) + "\n\t\t\t\t"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("address-modal", {
        attrs: {
          editable: _vm.isCanModify,
          "current-search": _vm.currSearch,
          "focus-supplier": _vm.editSupplierPayload,
        },
        on: {
          submit: _vm.handleAddressSubmit,
          "delete-supplier": _vm.handleDeleteSupplier,
          input: _vm.handleCloseModal,
        },
        model: {
          value: _vm.isAddressModalOpen,
          callback: function ($$v) {
            _vm.isAddressModalOpen = $$v
          },
          expression: "isAddressModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }