var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        size: "lg",
        title: _vm.modalTitle,
        "ok-variant": "success",
        "ok-title": _vm.okTitle,
        "ok-only": "",
      },
      on: { ok: _vm.onSubmit, hidden: _vm.onCancel },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("green-film-docs", {
                    attrs: {
                      GFId: _vm.taskId,
                      GFType: "task",
                      GFSubType: "offerDocumentTemplate",
                      "is-single-file": true,
                      "accept-files": ".doc,.docx,.odt",
                    },
                    on: {
                      "image-child:removed": _vm.onRemoved,
                      "image-child:added": _vm.onAdded,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }