<template>
	<div>
		<b-row class="header-table-offers">
			<b-col cols="3">
				{{ FormMSG(1, 'Name') }}
			</b-col>
			<b-col cols="3">
				{{ FormMSG(2, 'Description') }}
			</b-col>
			<b-col cols="1" class="text-center"> U </b-col>
			<b-col cols="1" class="text-center"> Q </b-col>
			<b-col cols="1" class="text-center"> PU </b-col>
			<b-col cols="1" class="text-center">
				{{ FormMSG(3, 'Total') }}
			</b-col>
			<b-col cols="2"></b-col>
		</b-row>
		<div class="content-table-offers">
			<TreeNode
				v-for="node in treeViewTasks"
				:key="node.id"
				:parent-node="task"
				:node="node"
				:line-number="''"
				:cur-index-parents="'' + 0"
				@tree-node:new-post="handleTreeNodeNewPost"
				@tree-node:edit-post="handleTreeNodeEditPost"
				@tree-node:delete-post="handleTreeNodeDeletePost"
				@tree-node:new-material="handleTreeNodeNewMaterial"
				@tree-node:edit-material="handleTreeNodeEditMaterial"
				@tree-node:delete-material="handleTreeNodeDeleteMaterial"
			/>
		</div>
		<post-modal-offer
			:open="showPostModalOffer"
			:task-selected="nodeSelected"
			:client-id="parseInt(task.clientId)"
			:has-index="hasIndex"
			@post-modal-offer:update="handlePostModalOfferUpdate"
			@post-modal-offer:close="handlePostModalOfferClose"
		/>
		<task-material-modal
			v-model="showMaterialModal"
			:material="materialSelected"
			@task-material-modal:cancel="handleMaterialModalCancel"
			@task-material-modal:taskMaterialUpdated="handleMaterialUpdated"
		/>
	</div>
</template>

<script>
import SvgPlus from './SvgPlus';
import SvgMinus from './SvgMinus';
import TreeNode from './TreeNode';
import languageMessages from '@/mixins/languageMessages';
import PostModalOffer from './PostModalOffer';
import TaskMaterialModal from '../Modals/TaskMaterialModal';
import { updatedTask, updatedTaskItem, delTaskItem, delTask, getTaskItem } from '@/cruds/task.crud';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'TableOffer',

	components: {
		SvgPlus,
		SvgMinus,
		TreeNode,
		PostModalOffer,
		TaskMaterialModal
	},

	mixins: [languageMessages, GlobalMixin],

	props: {
		task: { type: Object, default: () => {}, required: true },
		treeViewTasks: { type: Array, default: () => [], required: true }
	},

	data() {
		return {
			showPostModalOffer: false,
			curIndexParentSelected: '',
			nodeSelected: {},
			materialSelected: {},
			showMaterialModal: false,
			hasIndex: false,
			hasIndexMaterial: false
		};
	},

	methods: {
		handleMaterialModalCancel(payload) {
			this.showMaterialModal = payload;
		},
		handleMaterialUpdated(payload) {
			this.$emit('table-offer:update-material', {
				curIndexParents: this.curIndexParentSelected,
				material: payload,
				isUpdate: true,
				hasIndex: this.hasIndexMaterial
			});
		},
		handlePostModalOfferClose() {
			this.showPostModalOffer = false;
		},
		async handleTreeNodeEditMaterial(payload) {
			this.curIndexParentSelected = payload.curIndexParents;
			this.materialSelected = await getTaskItem(payload.material.id);
			this.hasIndexMaterial = payload.hasIndexMaterial;

			this.showMaterialModal = true;
		},
		handleTreeNodeEditPost(payload) {
			this.curIndexParentSelected = payload.curIndexParents;
			this.nodeSelected = payload.node;
			this.hasIndex = true;

			this.showPostModalOffer = true;
		},
		handlePostModalOfferUpdate(payload) {
			payload.node.items = this.nodeSelected.materials;
			this.$emit('table-offer:new-post', {
				curIndexParents: this.curIndexParentSelected,
				updTask: payload.node,
				isUpdate: true,
				hasIndex: payload.hasIndex
			});
		},
		async handleTreeNodeDeletePost(payload) {
			const deletePost = async () => {
				this.curIndexParentSelected = payload.curIndexParents;
				await delTask(payload.node.taskPlanningId, payload.node.id);

				this.$emit('table-offer:delete-post', {
					curIndexParents: this.curIndexParentSelected,
					node: payload.node,
					isUpdate: true
				});
			};

			this.confirmModal(deletePost, this.FormMSG(66, 'Are you sure ?'));
		},
		async handleTreeNodeDeleteMaterial(payload) {
			const deleteMaterial = async () => {
				this.curIndexParentSelected = payload.curIndexParents;
				await delTaskItem(payload.material.id);
				this.hasIndexMaterial = payload.hasIndexMaterial;
				this.materialSelected = payload.material;

				this.$emit('table-offer:delete-material', {
					curIndexParents: this.curIndexParentSelected,
					material: this.materialSelected,
					isUpdate: payload.isUpdate,
					hasIndex: payload.hasIndexMaterial
				});
			};

			this.confirmModal(deleteMaterial, this.FormMSG(66, 'Are you sure ?'));
		},
		async handleTreeNodeNewMaterial(payload) {
			this.curIndexParentSelected = payload.curIndexParents;
			this.materialSelected = await updatedTaskItem(0, {
				name: this.FormMSG(45, 'Default material'),
				taskId: parseInt(payload.node.id, 10)
			});
			this.hasIndexMaterial = payload.hasIndexMaterial;

			this.$emit('table-offer:update-material', {
				curIndexParents: payload.curIndexParents,
				material: this.materialSelected,
				isUpdate: false,
				hasIndex: payload.hasIndexMaterial
			});
			this.showMaterialModal = true;
		},
		async handleTreeNodeNewPost(payload) {
			this.curIndexParentSelected = payload.curIndexParents;

			let updTask = {
				name: this.FormMSG(125, 'Default post'),
				description: this.FormMSG(126, 'Default description post'),
				offerDate: new Date().toISOString(),
				offerMade: true,
				priority: 0,
				clientId: parseInt(this.treeViewTasks[0].clientId),
				locationId: parseInt(this.treeViewTasks[0].locationId),
				addressId: parseInt(this.treeViewTasks[0].addressId),
				taskTypeId: 0,
				putInPlanning: true,
				vat: 0,
				parentTaskId: parseInt(payload.node.id),
				status: 9,
				dayDuration: 1
			};
			const task = await updatedTask(0, updTask);
			updTask.id = task.id;
			updTask.taskPlanningId = task.taskPlanningId;
			this.$emit('table-offer:new-post', {
				curIndexParents: payload.curIndexParents,
				updTask,
				isUpdate: false,
				hasIndex: false
			});

			this.nodeSelected = updTask;
			this.hasIndex = false;

			this.showPostModalOffer = true;
		}
	}
};
</script>

<style>
.header-table-offers {
	background-color: #00a09c;
	border: 1px solid #ddd !important;
	margin-left: 0px;
	margin-right: 0;
}
.header-table-offers > div {
	border-right: 1px solid #ddd !important;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: bolder;
	color: white;
}
.content-table-offers .row {
	/* border: 1px solid #ddd !important; */
	margin-left: 0px;
	margin-right: 0;
}
.content-table-offers .row:hover {
	background-color: #f3f5f7;
}
.content-table-offers .row div {
	border-right: 1px solid #ddd !important;
	padding-top: 5px;
	padding-bottom: 5px;
}
</style>
