var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "header-table-offers" },
        [
          _c("b-col", { attrs: { cols: "3" } }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.FormMSG(1, "Name")) + "\n\t\t"),
          ]),
          _c("b-col", { attrs: { cols: "3" } }, [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.FormMSG(2, "Description")) + "\n\t\t"
            ),
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "1" } }, [
            _vm._v(" U "),
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "1" } }, [
            _vm._v(" Q "),
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "1" } }, [
            _vm._v(" PU "),
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "1" } }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.FormMSG(3, "Total")) + "\n\t\t"),
          ]),
          _c("b-col", { attrs: { cols: "2" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-table-offers" },
        _vm._l(_vm.treeViewTasks, function (node) {
          return _c("TreeNode", {
            key: node.id,
            attrs: {
              "parent-node": _vm.task,
              node: node,
              "line-number": "",
              "cur-index-parents": "" + 0,
            },
            on: {
              "tree-node:new-post": _vm.handleTreeNodeNewPost,
              "tree-node:edit-post": _vm.handleTreeNodeEditPost,
              "tree-node:delete-post": _vm.handleTreeNodeDeletePost,
              "tree-node:new-material": _vm.handleTreeNodeNewMaterial,
              "tree-node:edit-material": _vm.handleTreeNodeEditMaterial,
              "tree-node:delete-material": _vm.handleTreeNodeDeleteMaterial,
            },
          })
        }),
        1
      ),
      _c("post-modal-offer", {
        attrs: {
          open: _vm.showPostModalOffer,
          "task-selected": _vm.nodeSelected,
          "client-id": parseInt(_vm.task.clientId),
          "has-index": _vm.hasIndex,
        },
        on: {
          "post-modal-offer:update": _vm.handlePostModalOfferUpdate,
          "post-modal-offer:close": _vm.handlePostModalOfferClose,
        },
      }),
      _c("task-material-modal", {
        attrs: { material: _vm.materialSelected },
        on: {
          "task-material-modal:cancel": _vm.handleMaterialModalCancel,
          "task-material-modal:taskMaterialUpdated": _vm.handleMaterialUpdated,
        },
        model: {
          value: _vm.showMaterialModal,
          callback: function ($$v) {
            _vm.showMaterialModal = $$v
          },
          expression: "showMaterialModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }