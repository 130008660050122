<template>
	<div>
		<b-row style="border: 1px solid #dfdfdf !important; margin: 0" @click="$emit('tree-node:row-click', node.name)">
			<b-col sm="3">
				<div style="border-right: 0 !important" :style="{ marginLeft: nodeMargin + 'px' }">
					<SvgPlus v-if="hasChildren && !showChildren" @clicked="toggleChildren" />
					<SvgMinus v-if="hasChildren && showChildren" @clicked="toggleChildren" />
					{{ lineNumber }} {{ node.name }}
				</div>
			</b-col>
			<b-col sm="3">
				<div
					v-if="node.description.length > 0"
					v-b-popover.hover.right="`${node.description}`"
					:title="FormMSG(21, 'Description')"
					style="border-right: 0 !important"
				>
					{{ truncate(node.description, 50) }}
				</div>
			</b-col>
			<b-col sm="1" />
			<b-col sm="1" />
			<b-col sm="1" />
			<b-col sm="1" />
			<b-col sm="2" class="text-center">
				<b-dropdown variant="link" menu-class="w-100" no-caret right :disabled="parentNode.sendStatus === 5" style="border-right: 0 !important">
					<template #button-content>
						<component :is="getLucideIcon(ICONS.PLUS_CIRCLE.name)" :size="22" />
					</template>
					<b-dropdown-item @click="handleClickNewPost(node)">
						<span><i class="fa fa-plus-circle" style="font-size: 1.15rem; color: green" aria-hidden="true"></i></span
						>{{ FormMSG(48, 'Sub element') }}
					</b-dropdown-item>
					<b-dropdown-item @click="handleClickNewMaterial(node)">
						<span><i class="fa fa-cart-plus" style="font-size: 1.15rem; color: green" aria-hidden="true"></i></span>{{ FormMSG(49, 'Material') }}
					</b-dropdown-item>
					<b-dropdown-form>
						<div class="p-0 cb-block-context-menu lh-21 pb-2" style="border-right: 0 !important">
							<b-form-checkbox
								class="px-0 ml-3"
								style="border-right: 0 !important"
								v-model="node.prtPageBreak"
								@change="saveLayoutPageBreakDoc($event, node)"
								><span class="pl-2">{{ FormMSG(9, 'Break page (word) ?') }}</span></b-form-checkbox
							>
						</div>
						<div class="p-0 cb-block-context-menu lh-21 pb-2" style="border-right: 0 !important">
							<b-form-checkbox
								class="px-0 ml-3"
								style="border-right: 0 !important"
								v-model="node.prtLnDescriptionSection"
								@change="saveLayoutLineDescriptionDoc($event, node)"
								><span class="pl-2">{{ FormMSG(10, 'Separate description line (word) ?') }}</span></b-form-checkbox
							>
						</div>
						<div class="p-0 cb-block-context-menu lh-21 pb-1" style="border-right: 0 !important">
							<b-form-checkbox
								class="px-0 ml-3"
								style="border-right: 0 !important"
								v-model="node.prtHideDetails"
								@change="saveLayoutHideDetailDoc($event, node)"
								><span class="pl-2">{{ FormMSG(11, 'Hide details (word) ?') }}</span></b-form-checkbox
							>
						</div>
					</b-dropdown-form>
				</b-dropdown>
				<b-button variant="link" :disabled="lineNumber === '' || parentNode.sendStatus === 5" @click="handleClickTreeNodeEditPost(node)">
					<component :is="getLucideIcon(ICONS.PENCIL.name)" :color="ICONS.PENCIL.color" :size="22" />
				</b-button>
				<b-button variant="link" :disabled="lineNumber === '' || parentNode.sendStatus === 5" @click="handleClickTreeNodeDeletePost(node)">
					<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="22" />
				</b-button>
			</b-col>
		</b-row>
		<div v-if="hasMaterial">
			<b-row v-for="(material, index) in node.materials" :key="material.taskPlanningId" style="border: 1px solid #dfdfdf !important; margin: 0">
				<b-col sm="3">
					<span style="display: inline-block; color: #0d6efd" :style="{ marginLeft: nodeMargin + 40 + 'px' }">
						{{ lineNumber }}{{ initialMaterial }}.{{ index + 1 }}. {{ material.name }}
					</span>
				</b-col>
				<b-col sm="3"> - </b-col>
				<b-col sm="1" class="text-center">
					{{ getUnit(material) }}
				</b-col>
				<b-col sm="1" class="text-center">
					{{ material.test }}
					{{ material.quantity }}
				</b-col>
				<b-col sm="1" class="text-center">
					{{ currency(material.unitPrice) }}
				</b-col>
				<b-col sm="1" class="text-center">
					{{ currency(material.totalPrice) }}
				</b-col>
				<b-col sm="2" class="text-right">
					<b-button variant="primary" class="mr-2" :disabled="parentNode.sendStatus === 5" @click="handleClickTreeNodeEditMaterial(material)">
						<i class="icon-pencil" />
					</b-button>
					<b-button variant="danger" class="mr-2" :disabled="parentNode.sendStatus === 5" @click="handleClickTreeNodeDeleteMaterial(material)">
						<i class="icon-trash" />
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-if="hasChildren" v-show="showChildren">
			<TreeNode
				v-for="(child, index) in node.children"
				:key="child.taskPlanningId"
				:parent-node="parentNode"
				:node="child"
				:spacing="spacing + 10"
				:line-number="lineNumber + '.' + (index + 1)"
				:cur-index-parents="curIndexParents + ',' + index"
				@tree-node:new-post="handleTreeNodeNewPost"
				@tree-node:edit-post="handleTreeNodeEditPost"
				@tree-node:delete-post="handleTreeNodeDeletePost"
				@tree-node:new-material="handleTreeNodeNewMaterial"
				@tree-node:edit-material="handleTreeNodeEditMaterial"
				@tree-node:delete-material="handleTreeNodeDeleteMaterial"
			/>
		</div>
	</div>
</template>

<script>
import SvgPlus from './SvgPlus';
import SvgMinus from './SvgMinus';
import GlobalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency } from '~helpers';
import { mapFilters } from '~filters';
import { updatedTask } from '@/cruds/task.crud';
import { getFormItem } from '@/cruds/language.crud';

export default {
	name: 'TreeNode',
	components: {
		SvgPlus,
		SvgMinus
	},

	mixins: [languageMessages, GlobalMixin],

	props: {
		parentNode: {
			type: Object,
			required: true
		},
		node: {
			type: Object,
			required: true
		},
		spacing: {
			type: Number,
			default: 0
		},
		lineNumber: {
			type: String,
			default: ''
		},
		curIndexParents: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			showChildren: true,
			initialMaterial: ''
		};
	},
	async mounted() {
		this.initialMaterial = await this.getInitialTranslationMaterial();
	},
	computed: {
		units() {
			return this.FormMenu(1317);
		},
		nodeMargin() {
			return this.spacing;
		},
		hasChildren() {
			const { children } = this.node;
			return children && children.length > 0;
		},
		toggleChildrenIcon() {
			return this.showChildren ? 'fas fa-angle-down' : 'fas fa-angle-right';
		},
		hasMaterial() {
			const { materials } = this.node;
			return materials && materials.length > 0;
		}
	},
	methods: {
		...mapFilters(['truncate']),
		currency(val) {
			return rendCurrency(val);
		},
		handleTreeNodeDeletePost(payload) {
			this.$emit('tree-node:delete-post', payload);
		},
		handleTreeNodeDeleteMaterial(payload) {
			this.$emit('tree-node:delete-material', payload);
		},
		handleTreeNodeEditMaterial(payload) {
			this.$emit('tree-node:edit-material', payload);
		},
		handleTreeNodeNewMaterial(payload) {
			this.$emit('tree-node:new-material', payload);
		},
		handleTreeNodeNewPost(payload) {
			this.$emit('tree-node:new-post', payload);
		},
		handleTreeNodeEditPost(payload) {
			this.$emit('tree-node:edit-post', payload);
		},
		handleClickTreeNodeDeleteMaterial(material) {
			this.$emit('tree-node:delete-material', {
				material,
				curIndexParents: this.curIndexParents,
				isUpdate: true,
				hasIndexMaterial: true
			});
		},
		handleClickTreeNodeEditMaterial(material) {
			this.$emit('tree-node:edit-material', {
				material,
				curIndexParents: this.curIndexParents,
				isUpdate: true,
				hasIndexMaterial: true
			});
		},
		handleClickTreeNodeDeletePost(node) {
			this.$emit('tree-node:delete-post', {
				node,
				curIndexParents: this.curIndexParents,
				isUpdate: true
			});
		},
		handleClickTreeNodeEditPost(node) {
			this.$emit('tree-node:edit-post', {
				node,
				curIndexParents: this.curIndexParents,
				isUpdate: true
			});
		},
		handleClickNewMaterial(node) {
			this.$emit('tree-node:new-material', {
				node,
				curIndexParents: this.curIndexParents,
				isUpdate: false,
				hasIndexMaterial: false
			});
		},
		handleClickNewPost(node) {
			this.$emit('tree-node:new-post', {
				node,
				curIndexParents: this.curIndexParents,
				isUpdate: false
			});
		},
		handleTreeNodeRowClick(payload) {
			this.$emit('tree-node:node-click', payload);
		},
		toggleChildren() {
			this.showChildren = !this.showChildren;
		},
		getUnit(material) {
			if (material.unit === 0) {
				return material.customUnit;
			}
			const findUnit = this.units.find((u) => u.value === material.unit);
			return findUnit.text;
		},
		async saveLayoutPageBreakDoc(payload, item) {
			await updatedTask(item.id, {
				taskPlanningId: item.taskPlanningId,
				prtPageBreak: payload
			});
			// console.log('LayoutPageBreakDoc: ', {payload, item});
		},
		async saveLayoutLineDescriptionDoc(payload, item) {
			await updatedTask(item.id, {
				taskPlanningId: item.taskPlanningId,
				prtLnDescriptionSection: payload
			});
			// console.log('LayoutLineDescriptionDoc: ', {payload, item});
		},
		async saveLayoutHideDetailDoc(payload, item) {
			await updatedTask(item.id, {
				taskPlanningId: item.taskPlanningId,
				prtHideDetails: payload
			});
			// console.log('LayoutHideDetailDoc: ', {payload, item});
		},
		async getInitialTranslationMaterial() {
			const translationRow = await getFormItem(2966, 49);
			// console.log(translationRow !== null ? translationRow.message.charAt(0).toLowerCase() : '')
			return translationRow !== null ? translationRow.message.charAt(0).toLowerCase() : '';
		}
	}
};
</script>
<style lang="scss">
.cb-block-context-menu {
	min-width: 235px !important;
	width: auto;
}
</style>
