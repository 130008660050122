var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        id: "modal-offer",
        "hide-footer": "",
        size: _vm.$screen.width <= 992 ? "md" : "lg",
      },
      on: { hidden: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("span", [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.FormMSG(65, "Edit post")) + "\n\t\t"
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(72, "Name"),
                        "label-cols": "3",
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.dataToEdit.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "name", $$v)
                          },
                          expression: "dataToEdit.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(73, "Task type"),
                        "label-cols": "3",
                        horizontal: true,
                      },
                    },
                    [
                      _c("task-type-selector", {
                        attrs: {
                          "add-label": _vm.FormMSG(38, "Add"),
                          placeholder: _vm.FormMSG(34, "Choose type ..."),
                          "task-type-id": _vm.task.taskTypeId
                            ? _vm.task.taskTypeId
                            : 0,
                        },
                        on: {
                          "task-type:selected": _vm.selectType,
                          "task-type:clearSelection": _vm.clearType,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(74, "Description"),
                        "label-cols": "3",
                        horizontal: true,
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: { rows: "3" },
                        model: {
                          value: _vm.dataToEdit.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "description", $$v)
                          },
                          expression: "dataToEdit.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c(
                  "div",
                  [
                    _c("b-table", {
                      ref: "materialOfferTable",
                      attrs: {
                        hover: _vm.hover,
                        items: _vm.taskTypeItems,
                        fields: _vm.materialFields,
                        "current-page": _vm.currentPage,
                        "per-page": _vm.perPage,
                        "head-variant": _vm.hv,
                        responsive: "sm",
                        bordered: "",
                        small: "",
                        "empty-text": _vm.FormMSG(2, "No material found"),
                        "show-empty": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(unit)",
                          fn: function (data) {
                            return [
                              data.item.unit > 0
                                ? _c("div", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.units[data.item.unit].text) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(data.item.customUnit) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { disabled: _vm.formBusy },
                        on: { click: _vm.hideModal },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(10, "Close")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "success",
                          type: "submit",
                          disabled: _vm.formBusy,
                        },
                      },
                      [
                        _vm.formBusy
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(43, "Modification in progress")
                                )
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.FormMSG(42, "Save"))),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }