var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "error-container",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "6" } }, [
                        _c("h4", [_vm._v(_vm._s(_vm.FormMSG(1, "Offer")))]),
                      ]),
                      _c("b-col", { attrs: { sm: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "float-right" },
                          [
                            _c("share-information", {
                              staticClass: "mr-2",
                              attrs: {
                                "btn-label": _vm.FormMSG(59, "Share offer"),
                                "title-modal": _vm.FormMSG(
                                  60,
                                  "Share the offer to :"
                                ),
                                offer: _vm.task,
                                "name-modal": "modal-share-offer",
                                icon: "fa fa-share-alt",
                                "variant-btn": "outline-secondary",
                              },
                            }),
                            _c(
                              "b-dropdown",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  split: "",
                                  "split-variant": "outline-primary",
                                  variant: "primary",
                                  text: _vm.FormMSG(458, "Download"),
                                  "menu-class": "w-100",
                                  right: "",
                                },
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadOfferInPdf(_vm.task)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: { color: "red" },
                                          attrs: {
                                            icon: "file-earmark-pdf-fill",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(6, "PDF")) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadOfferInDoc(_vm.task)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: { color: "#317ac1" },
                                          attrs: {
                                            icon: "file-earmark-word-fill",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(7, "Word")) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadOfferInXls(_vm.task)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: { color: "green" },
                                          attrs: { icon: "file-excel-fill" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(8, "Excel")) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "btn btn-primary btn-md float-right",
                                style: `${!_vm.$screen.md ? "width:100%" : ""}`,
                                attrs: { variant: "success", size: "md" },
                                on: { click: _vm.backToTheList },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(this.FormMSG(2, "Back to list")) +
                                    " "
                                ),
                                _c("i", { staticClass: "icon-action-undo" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card-body",
                [
                  _c(
                    "b-tabs",
                    {
                      attrs: {
                        cards: "",
                        "active-nav-item-class": "text-uppercase",
                        "active-tab-class": "",
                        "title-item-class": "text-uppercase",
                        "content-class": "p-3",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.FormMSG(256, "Generals") } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("form-offer", {
                                    attrs: {
                                      task: _vm.task,
                                      "total-materials": _vm.totalBudget,
                                    },
                                    on: {
                                      "form-offer:update-info":
                                        _vm.handleFormOfferUpdateInfo,
                                      "form-offer:refreshTreeView":
                                        _vm.refreshTreeView,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("table-offer", {
                                    attrs: {
                                      task: _vm.task,
                                      "tree-view-tasks": _vm.treeViewTasks,
                                    },
                                    on: {
                                      "table-offer:new-post":
                                        _vm.handleTableOfferNewPost,
                                      "table-offer:delete-post":
                                        _vm.handleTableOfferDeletePost,
                                      "table-offer:update-material":
                                        _vm.handleTableOfferUpdateMaterial,
                                      "table-offer:delete-material":
                                        _vm.handleTableOfferDeleteMaterial,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.FormMSG(68, "Documents") } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { sm: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: {
                                                "no-body": "",
                                                "header-tag": "header",
                                                "footer-tag": "footer",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "header",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mr-2 text-success task-icon-card-header",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-file-word-o",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-muted task-label-card-header",
                                                            },
                                                            [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        3,
                                                                        "Offer Template"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "footer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "9",
                                                              },
                                                            },
                                                            [_vm._v(" ")]
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "3",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "my-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "py-2 px-4 tags-assigned",
                                                                      attrs: {
                                                                        type: "button",
                                                                        variant:
                                                                          "outline-primary",
                                                                        size: "md",
                                                                        disabled:
                                                                          _vm.documentTemplateLength >
                                                                            0 ||
                                                                          _vm
                                                                            .task
                                                                            .sendStatus ===
                                                                            5,
                                                                        block:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openDocumentTemplateModal,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                4,
                                                                                "Add"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        { attrs: { sm: "12" } },
                                                        [
                                                          _c(
                                                            "green-film-docs",
                                                            {
                                                              ref: "documentTemplateList",
                                                              attrs: {
                                                                GFId: _vm.id,
                                                                "show-input-file": false,
                                                                GFType: "task",
                                                                GFSubType:
                                                                  "offerDocumentTemplate",
                                                              },
                                                              on: {
                                                                "el:updated":
                                                                  _vm.offerDocumentTemplate,
                                                                "image-child:removed":
                                                                  _vm.onDocumentTemplateRemoved,
                                                              },
                                                            }
                                                          ),
                                                          _vm.elDocTemplateMounted ===
                                                            true &&
                                                          _vm.documentTemplateLength ===
                                                            0
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center empty-item-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          5,
                                                                          "No offer template selected"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: {
                                                "no-body": "",
                                                "header-tag": "header",
                                                "footer-tag": "footer",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "header",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mr-2 text-success task-icon-card-header",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-files-o",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-muted task-label-card-header",
                                                            },
                                                            [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        25,
                                                                        "Documents"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "footer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "9",
                                                              },
                                                            },
                                                            [_vm._v(" ")]
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "3",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "my-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "py-2 px-4 tags-assigned",
                                                                      attrs: {
                                                                        type: "button",
                                                                        variant:
                                                                          "outline-primary",
                                                                        size: "md",
                                                                        disabled:
                                                                          _vm
                                                                            .task
                                                                            .sendStatus ===
                                                                          5,
                                                                        block:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openDocumentModal,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                14,
                                                                                "Add"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        { attrs: { sm: "12" } },
                                                        [
                                                          _c(
                                                            "green-film-docs",
                                                            {
                                                              ref: "cardDocumentList",
                                                              attrs: {
                                                                GFId: _vm.id,
                                                                "show-input-file": false,
                                                                GFType: "task",
                                                                GFSubType:
                                                                  "documents",
                                                                "attachment-to-offer-order-invoice-only": true,
                                                              },
                                                              on: {
                                                                "el:updated":
                                                                  _vm.greenFilmDocsMounted,
                                                              },
                                                            }
                                                          ),
                                                          _vm.elDocMounted ===
                                                            true &&
                                                          _vm.greenFilmDocLength ===
                                                            0
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center empty-item-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          74,
                                                                          "No document selected"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: {
                                                "no-body": "",
                                                "header-tag": "header",
                                                "footer-tag": "footer",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "header",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mr-2 text-success task-icon-card-header",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-image",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-muted task-label-card-header",
                                                            },
                                                            [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        24,
                                                                        "Images"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "footer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "3",
                                                                offset: "9",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "my-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "py-2 px-4 tags-assigned",
                                                                      attrs: {
                                                                        type: "button",
                                                                        variant:
                                                                          "outline-primary",
                                                                        size: "md",
                                                                        disabled:
                                                                          _vm
                                                                            .task
                                                                            .sendStatus ===
                                                                          5,
                                                                        block:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.togglePictureModal,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                13,
                                                                                "Add"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _vm.task.images.length === 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          ref: "empty-image",
                                                          staticClass:
                                                            "text-center empty-item-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  52,
                                                                  "No image selected"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _c("ImgShowroomTable", {
                                                        attrs: {
                                                          "img-data":
                                                            _vm.images,
                                                        },
                                                        on: {
                                                          "image:removed":
                                                            _vm.imageRemoved,
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("task-document-modal", {
                    attrs: { "task-id": _vm.id, "is-offer": true },
                    on: { "task-document:hidden": _vm.onHideDocumentModal },
                    model: {
                      value: _vm.isOpenDocumentModal,
                      callback: function ($$v) {
                        _vm.isOpenDocumentModal = $$v
                      },
                      expression: "isOpenDocumentModal",
                    },
                  }),
                  _c("offer-document-template-modal", {
                    attrs: { "task-id": _vm.id },
                    on: {
                      "offer-document-template:hidden":
                        _vm.onHideDocumentTemplateModal,
                      "offer-document-template:added":
                        _vm.onDocumentTemplateAdded,
                      "offer-document-template:removed":
                        _vm.onDocumentTemplateRemoved,
                    },
                    model: {
                      value: _vm.isOpenDocumentTemplateModal,
                      callback: function ($$v) {
                        _vm.isOpenDocumentTemplateModal = $$v
                      },
                      expression: "isOpenDocumentTemplateModal",
                    },
                  }),
                  _c("capture", {
                    attrs: {
                      "multiple-img": "",
                      "dynamic-crop-size": "",
                      "edit-data": _vm.task,
                      "parent-id": _vm.id,
                      "parent-type": "task",
                      "parent-sub-type": "images",
                    },
                    on: { change: _vm.handleImgsTaked },
                    model: {
                      value: _vm.isCaptureModalOpen,
                      callback: function ($$v) {
                        _vm.isCaptureModalOpen = $$v
                      },
                      expression: "isCaptureModalOpen",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }