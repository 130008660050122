<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isOpen"
		size="lg"
		:title="modalTitle"
		ok-variant="success"
		:ok-title="okTitle"
		@ok="onSubmit"
		@hidden="onCancel"
		ok-only
	>
		<b-container fluid>
			<b-row>
				<b-col cols="12">
					<green-film-docs
						:GFId="taskId"
						GFType="task"
						GFSubType="offerDocumentTemplate"
						:is-single-file="true"
						accept-files=".doc,.docx,.odt"
						@image-child:removed="onRemoved"
						@image-child:added="onAdded"
					/>
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';

export default {
	name: 'OfferDocumentTemplateModal',
	mixins: [languageMessages],
	components: {
		GreenFilmDocs
	},
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		taskId: {
			type: [String, Number],
			required: true,
			default: '0'
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},
		modalTitle() {
			return this.FormMSG(1, 'Add offer template');
		},
		okTitle() {
			return this.FormMSG(2, 'Ok');
		}
	},
	methods: {
		onSubmit() {
			this.$emit('offer-document-template:hidden', this.taskId);
		},
		onCancel() {
			this.$emit('offer-document-template:hidden', this.taskId);
		},
		onRemoved(payload) {
			this.$emit('offer-document-template:removed', payload);
		},
		onAdded(payload) {
			this.$emit('offer-document-template:added', payload);
		}
	}
};
</script>
